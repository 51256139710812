<template>
  <transition-group name="fade" class="card-wrap" tag="div" appear>
    <router-link v-for="film in filmsSort" :key="film.id" :to="`/watch/${film.id}/${film.last_watch_video || 1}`"
      class="card" :style="`background: url(${film.img}) 50% 50% / cover;`">
      <div class="title">{{ film.name }} ({{ getLastAddVideo(film) }}/{{ film.full_count_video }})</div>
      <div class="last_added_video" :class="{
      new: NormolizeVideoTitle(getLastAddVideo(film)) != NormolizeVideoTitle(getLastSeriaTitle(film)),
    }">
        {{ NormolizeVideoTitle(getLastSeriaTitle(film)) + "/" + (NormolizeVideoTitle(getLastAddVideo(film))) || "" }}
      </div>
    </router-link>
  </transition-group>
</template>

<script>
import Icon from './Icon.vue';

export default {
  methods: {
    NormolizeVideoTitle(value = "") {
      return value.split('-')[1] || value;
    },
    getLastSeriaTitle(film) {
      if (film?.series) {
        let title = film.series[film.last_watch_video]?.title;
        if (title)
          return title;
        else {
          let res;
          for (const [key, value] of Object.entries(film.series)) {
            if (value.id == this.$route.params.seria_id) {
              res = value.title;
            }
          }
          return res;
        }
      }
      else
        return film.last_watch_video;
    },
    getLastAddVideo(film) {
      if (film?.series) {
        return film?.series[film.last_added_video]?.title;
      }
      else
        return film.last_added_video;
    },
  },
  props: {
    filmsSort: {
      type: Array,
      default: [],
    },
  },
  components: { Icon }
};
</script>

<style lang="scss">
.card-wrap {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: calc((100% - 120px) / 6);
  height: 275px;
  border-radius: 15px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 10px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;

  @media (max-width: 1023px) {
    width: calc(50% - 10px);
    margin: 5px;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgb(0 0 0 / 50%);
  }

  .title {
    background: rgba(0, 0, 0, 0.5098039216);
    padding: 10px;
    backdrop-filter: blur(5px);
    font-size: 16px;
    box-shadow: 0 0 1px 1px #4f4f4f8f;
    transition: 0.3s;
    overflow: hidden;
  }
}

.next_video_date {
  font-size: 14px;
  text-shadow: 1px 1px 1px black, -1px -1px 1px black;
  max-height: 0;
  padding: 0 5px;
  opacity: 0;
  transition: 0.3s;
}

.last_added_video {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  font-size: 16px;
  background: #ddd;
  border-radius: 15px 0 10px 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #00000082;
  box-shadow: 0 0 10px black;
}

.last_added_video.new {
  background: #a5000082;
}

.last_watch_video {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  font-size: 25px;
  background: #ddd;
  border-radius: 0 15px 10px 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #00000082;
  box-shadow: 0 0 10px black;
}
</style>
