<template>
  <div>
    <div class="bg" :style="`background-image: url(/images/bg.jpg)`">
      <div class="title_wrap">
        <div class="title"><span style="display: flex;">Твой набор тайтлов</span>
          <div class="search">
            <input class="search" type="text" placeholder="Поиск" v-model="search" />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <transition mode="out-in" name="fade" appear>
        <cards v-if="filmsSort" :filmsSort="filmsSort" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cards from "@/components/Cards.vue";
import Icon from "@/components/Icon.vue";

export default {
  name: "HomeView",
  data() {
    return {
      search: ""
    }
  },
  components: {
    Cards,
    Icon,
  },
  computed: {
    ...mapGetters(["films"]),

    filmsSort() {
      return this.films.sort((a, b) => {
        const reg = (value = "") => value.split('-')[1] || value;
        const bb = reg(b?.last_added_video?.toString()) - reg(b?.last_watch_video?.toString());
        const aa = reg(a?.last_added_video?.toString()) - reg(a?.last_watch_video?.toString());
        return bb - aa;
      }).filter(e => e.name?.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  methods: {
    ...mapActions(['fetchFilms']),
  },
  async mounted() {
    await this.fetchFilms();
  },
};
</script>

<style lang="scss">
.content {
  @media (max-width: 1023px) {
    padding: 5px;
  }
}

.search {
  transition: .3s;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
