import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    films: [],
    timeCodesServer: [],
    timeCodes: [],
    preloader: false,
    videos: [],
    history: [],
    subscrybes: [],
    notifications: [],
  },
  getters: {
    notifications: (s) => s.notifications,
    subscrybes: (s) => s.subscrybes,
    preloader: (s) => s.preloader,
    history: (s) => s.history || [],
    user: (s) => s.user || JSON.parse(localStorage.getItem("user")),
    films: (s) => s.films || JSON.parse(localStorage.getItem("films")),
    videos: (s) => s.videos || [],
    timeCodesServer: (s) => s.timeCodesServer || [],
  },
  mutations: {
    setUser(s, payload) {
      s.user = payload;
      localStorage.setItem("user", JSON.stringify(payload));
    },

    setFilms(s, payload) {
      s.films = payload.map((e) => ({
        ...e.film,
        last_watch_video: e.last_watch_video,
      }));
      s.subscrybes = s.films.map((e) => e.id);
      localStorage.setItem("films", JSON.stringify(payload));
    },

    setFilm(s, film) {
      const indx = s.films.findIndex((f) => f.id == film.id);

      if (indx == -1) {
        s.films.push(film);
      } else {
        s.films[indx] = film;
      }

      localStorage.setItem("films", JSON.stringify(s.films));
    },

    setTimeCodes(s, payload) {
      payload.forEach((e) => {
        localStorage.setItem(e.key, e.value);
      });
    },
    setTimeCodesServer(s, payload) {
      s.timeCodesServer = payload;
    },
    setNotifications(s, payload) {
      s.notifications = payload;
    },
    setVideos(s, payload) {
      s.videos = payload;
    },
    setHistory(s, payload) {
      s.history = payload;
    },
    setVideo(s, payload) {
      s.videos[s.videos.findIndex((e) => e.id == payload.id)] = payload;
    },
    setPreloader(s, val) {
      s.preloader = val;
    },
  },
  actions: {
    async getAuth({ getters, commit }, params) {
      if (!params.noLoad) commit("setPreloader", true);
      let uri = params.uri;
      let query = params.query || "";
      let method = params.method || "get";

      var requestOptions = {
        method,
      };

      if (method == "post") {
        let body = JSON.stringify(params.body || "");
        requestOptions.body = body;
      }
      let nick = getters.user.name;
      let url = `${process.env.VUE_APP_SERVER_URL}api/${uri}?user=${nick}&${query}`;
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        commit("setPreloader", false);
        return await response.json();
      } else {
        console.log("Ошибка HTTP: " + response.status);
      }
      commit("setPreloader", false);
    },
    async post({ commit }, params) {
      commit("setPreloader", true);
      let uri = params.uri;
      let query = params.query || "";
      var requestOptions = {
        method: "post",
      };
      let url = `${process.env.VUE_APP_SERVER_URL}api/${uri}?${query}`;

      let response = await fetch(url, requestOptions);
      if (response.ok) {
        commit("setPreloader", false);
        return await response.json();
      } else {
        console.log("Ошибка HTTP: " + response.status);
      }
      commit("setPreloader", false);
    },
    async login({ dispatch, commit }, nick) {
      let user = await dispatch("post", {
        uri: "login",
        query: `user=${nick}`,
      });
      commit("setUser", user.data);
      await dispatch("fetchFilms");

      return user;
    },
    async fetchFilms({ dispatch, commit }) {
      let films = await dispatch("getAuth", {
        uri: "films",
      });
      commit("setFilms", films.data);
      return films;
    },
    async fetchFilm({ dispatch, commit }, id) {
      let film = await dispatch("getAuth", {
        uri: "films/" + id,
      });
      commit("setFilm", film.data);

      return film;
    },
    async fetchTimeCodes({ dispatch, commit }) {
      let resp = await dispatch("getAuth", {
        uri: "time-code",
      });
      commit("setTimeCodesServer", resp.data);
      return await resp.success;
    },
    async updateTimeCodes({ dispatch, commit }, body) {
      if (body.length == 0) return;
      let uri = "/time-code/";
      let resp = await dispatch("getAuth", { uri, body, method: "post", noLoad: true });
      commit("setTimeCodesServer", resp.data);
      return resp.data;
    },
    async getVideos({ dispatch, commit, getters }, params) {
      let film_id = params.film_id;

      let res = await dispatch("getAuth", {
        uri: `films/${film_id}/videos`,
      });

      if (res.success) {
        const videos = res.data;
        commit("setVideos", videos);
      } else alert("ERROR: " + res.message);
      return res;
    },
    async getHistory({ dispatch, commit }, params) {
      let res = await dispatch("getAuth", {
        uri: `/history`,
      });

      if (res.success) {
        const history = res.data;
        commit("setHistory", history);
      } else alert("ERROR: " + res.message);
      return res;
    },
    async addHistory({ dispatch, commit, getters }, params) {
      let seria_id = params.seria_id;
      let film_id = params.film_id;

      if (!seria_id) {
        alert("ERROR: серия должна быть цифрой");
        return { success: false };
      }

      let res = await dispatch("getAuth", {
        uri: `history/${film_id}`,
        query: `video=${seria_id}`,
        method: "POST",
      });

      if (res.success) {
        return res.data;
      } else alert("ERROR: " + res.message);
      return res;
    },
    async getSeria({ dispatch, commit, getters }, params) {
      let seria_id = params.seria_id;
      let film_id = params.film_id;

      if (!seria_id) {
        alert("ERROR: серия должна быть цифрой");
        return { success: false };
      }
      let res = await dispatch("getAuth", {
        uri: `watch/${film_id}`,
        query: `video=${seria_id}`,
      });

      if (res.success) {
        const video = res.data;
        commit("setVideo", video);
        return video;
      } else alert("ERROR: " + res.message);
      return res;
    },
    async addFilm({ dispatch, commit, getters }, id) {
      if (!id) return;
      const body = { id };
      let uri = "/films/add";
      let resp = await dispatch("getAuth", { uri, body, method: "post" });
      if (resp.success) alert(resp.data.name + " Удачно добавлено");
      else alert("ERROR: " + resp.message);
      resp = await dispatch("fetchFilms");
      return resp.data.find((e) => e.id == id);
    },
    async dellFilm({ dispatch, commit, getters }, id) {
      if (!id) return;
      let uri = "/films/dell/" + id;
      let resp = await dispatch("getAuth", { uri, method: "delete" });
      if (resp.success) alert("Удачно удалено");
      else alert("ERROR: " + resp.message);
      return resp.data;
    },
    async fetchNotifications({ dispatch, commit }) {
      let resp = await dispatch("getAuth", {
        uri: "notifications",
      });
      commit("setNotifications", resp.data);
      return await resp.success;
    },
    async readNotofications({ dispatch, commit }, id) {
      if (id == 0) return;
      let uri = "notifications/" + id;
      let resp = await dispatch("getAuth", { uri, method: "post", noLoad: true });
      commit("setNotifications", resp.data);
      return resp.data;
    },
  },
  modules: {},
});
