<template>
  <header>
    <router-link to="/" class="logo" style='background: url(/images/logo.png)'>
    </router-link>
    <div class="profile">
      <div style="cursor: pointer" class="history" @click="fullScreenTogle()">
        <Icon name="maximize" />
      </div>
      <div style="cursor: pointer" class="history" @click="$router.push('/history')">
        <Icon name="clock" />
      </div>
      <div style="cursor: pointer" class="add-video" @click="addVideo()">
        <Icon name="video" />
      </div>
      <div class="notiffications">
        <Icon :class="noRead ? 'noRead' : ''" name="bell" @click="notificationsShow = !notificationsShow" />
        <div v-if="notificationsShow" class="notiffications__bg" @click="notificationsShow = false"></div>
        <transition name="fade">
          <div v-if="notificationsShow" class="notiffications__content">
            <router-link :class="n.read ? '-read' : ''" :to="`/${n.url}`" class="notiffications__item"
              v-for="n in notificationsSort" :key="'n' + n.id" @click="() => (notificationsShow = false)">
              <div class="notiffications__title">
                <span v-if="!n.read">🆕</span>
                <span :class="!n.read ? 'bold' : ''">{{ n.title }}</span>
              </div>
              <div class="notiffications__item-content">
                <img class="notiffications__img" :src="n.img" />
                <div class="notiffications__text">
                  <div v-html="n.text"></div>
                  <div style="font-size: 13px">Последняя просмотренная {{ n?.last_view }}</div>
                  <br>
                  <span style="font-size: 13px">({{ normTime(n.date) }})</span>
                </div>
              </div>
            </router-link>
            <h4 style="text-align: center; margin: 15px;" v-if="notifications.length == 0">
              Уведомлений нет... <br>
              <span style="font-size: 100px;">👍</span>
            </h4>
          </div>

        </transition>
      </div>
      <div class="user" :style="`background-image: url(${user.avatar})`"></div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Icon from "./Icon.vue";
export default {
  name: "HeaderSide",
  props: {
    user: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      notificationsShow: false
    }
  },
  methods: {
    ...mapActions(["addFilm", "fetchNotifications", "readNotofications"]),
    addVideo() {
      let film_id = prompt("id или ссылка кина, вводи!");
      if (film_id)
        this.addFilm(film_id);
      else
        alert("Поле, которое нужно заполнить, НУЖНО ЗАПОЛНИТЬ!");
    },
    fullScreenTogle() {
      document.fullscreen ? this.exitFullscreen() : this.setFullScreen();
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Для Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Для Chrome, Safari и Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* Для IE/Edge */
        document.msExitFullscreen();
      }
    },
    setFullScreen() {
      const elem = document.documentElement;
      try {
        if (elem.requestFullscreen) {
          setTimeout(() => {
            elem.requestFullscreen();
          }, 500);
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
      } catch (error) { }
    },
    normTime(date) {
      var options = {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Intl.DateTimeFormat("ru-RU", options).format(new Date(date));
    }
  },
  computed: {
    ...mapGetters(['notifications', 'films']),
    noRead() {
      return !!this.notifications.filter(e => !e.read).length
    },
    notificationsSort() {
      const sorted = this.notifications.sort((a, b) => +b.id - +a.id);
      const noRead = sorted.filter(n => n.read === 0)
      const read = sorted.filter(n => n.read === 1)
      const res = [...noRead, ...read].map(el => {
        const regex = /watch\/([0-9]+)\//gm;
        const film_id = regex.exec(el.url)[1]
        const seria_id = this.films.find(f => f.id == film_id)?.last_watch_video
        return {
          ...el,
          url: `watch/${film_id}/${seria_id}`,
          last_view: seria_id
        }
      })
      return res
    }
  },
  async mounted() { await this.fetchNotifications() },
  components: { Icon }
};
</script>

<style lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  max-width: 1360px;
  margin: auto;
  padding: 0 20px;

  .logo {
    height: 30px;
    width: 173px;

    @media (max-width: 1023px) {
      width: 35px;
    }
  }

  .profile {
    display: flex;
    align-items: center;

    .add-video,
    .notiffications,
    .history {
      margin: 0 10px;
      color: rgba(0, 0, 0, .5);

      &:hover {
        color: #484848;
      }
    }

    .user {
      border-radius: 10px;
      height: 40px;
      width: 40px;
      background: url() 50% 50% / cover;
      margin-left: 30px;
    }
  }

  .notiffications {
    position: relative;

    svg {
      cursor: pointer;

      @keyframes fadeAnimation {
        0% {
          opacity: 0.3;
        }

        50% {
          opacity: 1;
        }

        100% {
          opacity: 0.3;
        }
      }

      &.noRead {
        color: rgb(109 94 211);
        animation: fadeAnimation 1s infinite;
        transition: opacity 0.5s;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      color: #484848;
      position: absolute;
      background: #fff;
      z-index: 20;
      box-shadow: 0 0 15px rgba(0, 0, 0, .5);
      border-radius: 8px;
      width: 350px;
      transform: translate(calc(-50% - 51px));
      overflow: auto;
      max-height: 600px;

      @media (max-width: 1023px) {
        max-height: calc(100vh - 50px);
      }
    }

    &__title {
      margin-bottom: 10px;
    }

    &__item {
      padding: 10px;
      border-bottom: 1px solid #ddd;
      transition: .3s;

      &:last-child {
        border: none;
      }

      &:hover {
        background: #00000017;
      }

      &.-read {
        opacity: .6;
      }
    }

    &__item-content {
      display: flex;
      gap: 10px;
    }

    &__img {
      max-width: 45px;
      border-radius: 13px;
    }

    &__bg {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
    }
  }
}
</style>
