<template>
  <svg class="feather">
    <use :xlink:href="`/images/feather-sprite.svg#${name}`" />
  </svg>
</template>

<script>

export default ({
  name: "Icon",
  props: {
    name: {
      type: String,
      default: ""
    }
  },
})
</script>

<style lang="scss">
.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
</style>