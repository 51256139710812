import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueVideoPlayer from "@videojs-player/vue";

import "video.js/dist/video-js.css";

const app = createApp();

app.use(VueVideoPlayer);

createApp(App).use(store).use(router).use(VueVideoPlayer).mount("#app");
